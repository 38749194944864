import { isGovCloudRegion, sha256 } from './util';
import * as microSpa from './microSpa';
import * as pendoConfig from './pendo-config';

const pendoScriptID = 'c1PendoScript';
export let isPendoInstalled = false;

/**
 * Pendo script is only loaded and initialized once user has signed-in.
 * We first insert the script into the DOM, and then we initialize it for the current user.
 * In the event user properties change, we re-initialize, but don't need to re-insert the script.
 */
export const installPendo = async (
  cloudOneAccountID,
  cloudOneUserURN,
  authUserType,
  screenResolution
) => {
  // skip initializing pendo if its not enabled or the config is somehow not defined
  if (!(pendoConfig?.pendoEnabled ?? false)) {
    console.debug('pendo config not available, not attempting to install.');
    return;
  }

  // we proceed to initialize the pendo agent now that we know its enabled

  // Prepare meta data for Pendo Script
  console.debug(`cloudOneAccountID: ${cloudOneAccountID}`);
  console.debug(`cloudOneAccountUserURN: ${cloudOneUserURN}`);
  console.debug(`authUserType: ${authUserType}`);
  console.debug(`screenResolution: ${screenResolution}`);

  // make sure the script is there
  await insertPendoScriptIfNeeded();

  // Now initialize the script for the current user
  if (!isGovCloudRegion) {
    initializePendo(
      cloudOneAccountID,
      cloudOneUserURN,
      authUserType,
      screenResolution,

      // this is the function that gets run once pendo is successfully initialized
      () => {
        console.debug('Pendo is ready!');
        isPendoInstalled = true;
      }
    );
  }
};

export function uninstallPendo() {
  // we cannot remove the script here - we could remove the script we inserted, but it
  // inserts the other pendo related scripts and we have no way of tracking that to remove it later.
  // not recommended.

  // what was recommended by pendo to help is the following, - basically tell
  // pendo to stop sending events. When we re-login, we'll call initialize again
  // and life will be good. Use optional chaining in case pendo is not loaded.
  // we also clear our ids by reseting to defaults by updating the options within pendo library,
  // they need to be explicitly set to something otherwise they are not wiped.
  if (typeof pendo !== 'undefined') {
    pendo.stopSendingEvents?.();
    pendo.updateOptions?.({
      visitor: { id: 'CLEARED' },
      account: { id: 'CLEARED' },
    });
  }
}

// This is the pendo snippet that loads the pendo agent which does all the pendo heavy lifting.
// the contents of this are pulled and modified only slightly straight from pendo install instructions.
// we've updated this in one place, to load the pendo.js statically, so it doesn't need to accept the api key
function getPendoInstallSnippet() {
  return `(function(){
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=[];
    v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src = '/static-${BUILD_TIMESTAMP}/pendo/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
})();`;
}

// this method initializes pendo for the current user, the pre-requisite being that the
// pendo script is present in the DOM.

function initializePendo(
  cloudOneAccountID,
  cloudOneUserURN,
  authUserType,
  screenResolution,
  pendoIsReady
) {
  const visitor = {
    id: cloudOneUserURN,
    // email:        // Recommended if using Pendo Feedback, or NPS Email
    // full_name:    // Recommended if using Pendo Feedback
    auth: authUserType, // Added this for ssov1/ssov2 authentications
    resolution: screenResolution, // Added this for screen resolutions
    // You can add any additional visitor level key-values here,
    // as long as it's not one of the above reserved names.
  };

  const account = {
    id: cloudOneAccountID,
    // name:         // Optional
    // is_paying:    // Recommended if using Pendo Feedback
    // monthly_value:// Recommended if using Pendo Feedback
    // planLevel:    // Optional
    // planPrice:    // Optional
    // creationDate: // Optional
    // You can add any additional account level key-values here,
    // as long as it's not one of the above reserved names.
  };

  // this is the logout and login case.
  // if we're already installed, we just update options, and reverse any actions performed during uninstall.
  if (isPendoInstalled) {
    console.debug(
      `calling pendo updateOptions with userUrn: ${cloudOneUserURN}, account: ${cloudOneAccountID}`
    );
    pendo.updateOptions({
      visitor: visitor,
      account: account,
    });

    // start sending events again that we stopped doing during uninstall.
    pendo.startSendingEvents();

    return;
  }

  // Otherwise, call the heavier, one time only initialize - note that if this is re-called
  // the ready event will not be re-triggered nor is it recommended to call this multiple times,
  // hence we do the lighter updateOptions above if its already in the DOM and ready to go.
  console.debug(
    `calling pendo initalize with userUrn: ${cloudOneUserURN}, account: ${cloudOneAccountID}`
  );
  pendo.initialize({
    // as specified in the api docs, if we're self hosting we need to specify this
    apiKey: pendoConfig.pendoGUID,

    visitor: visitor,
    account: account,

    events: {
      ready: pendoIsReady,
      validateGuide: pendoGuideValidation,
    },

    sanitizeUrl: pendoSanitizeURL,
  });
}

async function insertPendoScriptIfNeeded() {
  if (!document.getElementById(pendoScriptID)) {
    const doc = window.document;
    console.debug(`Inserting Pendo script...`);
    const pendoInstallSnippet = getPendoInstallSnippet();
    await microSpa.appendScriptTextWithPromise(pendoScriptID, pendoInstallSnippet, doc.body);
    console.debug('Inserted pendo script, tag:', document.getElementById(pendoScriptID));
  } else {
    console.debug(`Skip Pendo script insertion since it already exists`);
  }
}

// this is passed in to the initialize above to sanitize all URLs being sent to pendo
// return the url for query param included in the allowedQueryParamList
// and  prune out all post the ? for params not included in the allowedQueryParamList
function pendoSanitizeURL(url) {
  const allowedQueryParamList = ['referrer', 'dashboard'];
  if (allowedQueryParamList.some((param) => url.includes(param))) {
    return url;
  } else {
    return url.replace(/\?.*$/, '?redactedparms');
  }
}

// this is passed in to initialize and is our guide validation. the spec has it return a promise
// that does the validation, and that promise must throw if it doesn't want to validate
// the given guide.
async function pendoGuideValidation(hashableGuideString) {
  if (!pendoConfig.pendoGuideValidationEnabled) {
    console.debug('pendo guide validation disabled, not validating.');
    return true;
  }

  console.debug('in pendo guide validation for hashable: ' + hashableGuideString);
  const hashedGuideString = await sha256(hashableGuideString);
  console.debug('in pendo guide validation for hashed: ' + hashedGuideString);
  const result = pendoConfig.pendoHashedGuideStrings.includes(hashedGuideString);
  console.debug(
    'validating pendo guide, result: ' +
      result +
      ', hash: ' +
      hashedGuideString +
      ', hashable: ' +
      hashableGuideString
  );

  if (result != true) {
    console.debug("pendo guide didn't validate, not accepting");
    throw "pendo guide didn't validate, not accepting";
  }

  return result;
}
