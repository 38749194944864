import { setupFeatureFlagsListeners } from './services/launch-darkly-service';

const maintenanceModeConfig = {
  enabled: false,
  bannerMessage: {}, // { en: 'Maintenance message for English', ja: 'Maintenance message for Japanese' }
  servicesRestrictions: {}, // { serviceId: ['region1', 'region2'] }
};

export const setMaintenanceModeConfig = ({ bannerMessage = {}, servicesRestrictions = {} }) => {
  // if bannerMessage.en is not set, maintenance mode is disabled
  maintenanceModeConfig.enabled = Boolean(bannerMessage && bannerMessage?.en);
  maintenanceModeConfig.bannerMessage = { ...bannerMessage };
  maintenanceModeConfig.servicesRestrictions = { ...servicesRestrictions };
};

const parseMaintenanceModeFlags = (value) => {
  if (!value) {
    return {};
  }

  try {
    if (typeof value === 'object') {
      return value;
    }

    if (typeof value === 'string') {
      return JSON.parse(value);
    }
  } catch (e) {
    console.warn('parseMaintenanceModeFlags(): Invalid flag value', e);
  }

  return {};
};

// determines whether to restrict access to a service during maintenance mode
export const checkMaintenanceModeServiceRestrictions = (accountRegion, serviceId) => {
  if (!maintenanceModeConfig?.enabled === true) {
    return false;
  }

  const { servicesRestrictions = {} } = maintenanceModeConfig;
  const serviceInMaintenanceMode = servicesRestrictions?.[serviceId]?.includes(accountRegion);
  return serviceInMaintenanceMode;
};

// sets up the listener and dispatches the event when the maintenance mode flag is updated
export const setupMaintenanceModeFlagsListener = (globalEventDistributor) =>
  setupFeatureFlagsListeners(['c1-maintenance-mode-config'], (flags) => {
    const parsedFlagValues = parseMaintenanceModeFlags(flags['c1-maintenance-mode-config']);
    setMaintenanceModeConfig(parsedFlagValues);

    if (maintenanceModeConfig.enabled) {
      globalEventDistributor.dispatch({
        type: 'MAINTENANCE_MODE_CONFIG_UPDATED',
        maintenanceMode: maintenanceModeConfig,
      });
    } else {
      globalEventDistributor.dispatch({
        type: 'MAINTENANCE_MODE_CONFIG_UPDATED',
        maintenanceMode: {
          enabled: false,
        },
      });
    }
  });
