import { getAccountLinks, getSessionStorageJSONValue, handleAccountRoleID } from './helpers';
import { globalEventDistributor } from '../web-app-shell';
import { delay } from '../util';

const region = DEPLOYMENT_TARGET === 'alpha' || DEPLOYMENT_TARGET === 'dev' ? 'trend-us-1' : 'us-1'; // Used for ssoV1 accounts call only
let refreshTokensTimer;

// SSO V2
export const fetchCloudOneToken = async (idToken, account, retries = 2) => {
  if (!idToken || !account) {
    console.log('Missing token and/or account');
    clearInterval(refreshTokensTimer);
    return;
  }

  clearInterval(refreshTokensTimer);

  try {
    const config = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-store',
      headers: {
        'api-version': process.env.API_VERSION,
        Authorization: `Bearer ${idToken}`,
      },
    };

    const tokenURL = getTokensEndPoint(account);

    if (!tokenURL) {
      console.error('No token end-point found');
      return;
    }

    const response = await fetch(tokenURL, config);

    if (response.ok) {
      const { token } = await response?.json();
      const {
        exp,
        cloudOne: { principal, role },
      } = await getCallerIdentity(tokenURL, token);

      // update shell state with new token
      globalEventDistributor.dispatch({
        type: 'STORE_C1_TOKEN',
        c1Token: {
          token: token,
          expires: exp,
          userUrn: principal?.urn ?? '',
          roleName: role.name,
          role: principal?.role ?? '',
          serviceRoleUrnList: role?.serviceRoleURNs ?? [],
        },
      });

      const expiresInSeconds = exp - 60;

      refreshTokensTimer = setInterval(() => {
        console.debug('timer started...');
        const idToken = sessionStorage.getItem('idToken');
        if (!idToken) {
          console.debug('timer cleared...');
          return clearInterval(refreshTokensTimer);
        }

        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        if (currentTimeInSeconds >= expiresInSeconds) {
          clearInterval(refreshTokensTimer);
          console.debug('Getting latest token');
          return refreshTokens(idToken, token, account);
        }
      }, 30 * 1000); // 30 seconds
      return { token, expires: exp };
    } else {
      const { message = '' } = await response.json();
      if (response.status === 403 && message.toLowerCase() === 'mfa required') {
        globalEventDistributor.dispatch({
          type: 'NAVIGATE_APP',
          appName: 'account-selection',
        });
      } else if (response.status === 403 && message.toLowerCase().startsWith('user not found')) {
        if (retries === 0) throw new Error(message);
        if (retries > 0) {
          await delay(5000); // Wait 5 sec between each request
          return fetchCloudOneToken(idToken, account, retries - 1);
        }
      } else if (
        response.status === 403 &&
        message.toLowerCase().startsWith('user is not authorized')
      ) {
        globalEventDistributor.dispatch({ type: 'LOGGING_OUT' });
      }
      return { token: '', expires: '' };
    }
  } catch (e) {
    const error = `Error fetching C1 Token: ${e}`;

    globalEventDistributor.dispatch({
      type: 'ERROR_FETCHING_C1_TOKEN',
      errorMsg: error,
    });

    return { token: '', expires: '' };
  }
};

// SSO V1
export const fetchCloudOneTokenWithID = async (accountID, updateLastActivity = true) => {
  if (!accountID) {
    return undefined;
  }

  let tokenServiceURL;
  switch (DEPLOYMENT_TARGET) {
    case 'prod':
      tokenServiceURL = `https://accounts.${region}.cloudone.trendmicro.com/api/accounts/${accountID}/tokens`;
      break;
    case 'stage':
      tokenServiceURL = `https://accounts.${region}.staging-cloudone.trendmicro.com/api/accounts/${accountID}/tokens`;
      break;
    case 'drsim':
      tokenServiceURL = `https://accounts.${region}.drsim-cloudone.trendmicro.com/api/accounts/${accountID}/tokens`;
      break;
    case 'alpha':
    case 'dev':
      tokenServiceURL =
        window.location.hostname === 'localhost'
          ? '/api/accounts/102040608100/tokens'
          : `https://accounts.${region}.dev-cloudone.trendmicro.com/api/accounts/${accountID}/tokens`;
      break;
    default:
      tokenServiceURL = null;
      return console.error('No token service end-point found');
  }

  try {
    const config = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-store',
      credentials: 'include',
      headers: {
        Authorization: `sid+rid ${sessionStorage.getItem('rID')}`,
        updatelastactivity: updateLastActivity.toString(),
        'api-version': process.env.API_VERSION,
      },
    };
    const result = await fetch(tokenServiceURL, config);
    if (result.ok) {
      // extract new token
      const { token, expires } = await result.json();
      const {
        cloudOne: { principal, role },
      } = await getCallerIdentity(tokenServiceURL, token);

      // set payload structure
      const c1TokenPayload = {
        token: token,
        expires,
        userUrn: principal?.urn ?? '',
        roleName: role.name,
        role: principal?.role ?? '',
        serviceRoleUrnList: role?.serviceRoleURNs ?? [],
      };
      // Redux Dispatch
      globalEventDistributor.dispatch({
        type: 'STORE_C1_TOKEN',
        c1Token: { ...c1TokenPayload },
      });
      // Storing latest c1Token in Custom Event
      const getC1TokenEvent = new CustomEvent('latestC1Token', {
        detail: { ...c1TokenPayload },
      });
      // Dispatch Event
      window.dispatchEvent(getC1TokenEvent);
      return { ...c1TokenPayload };
    } else {
      let error;
      if (result.status === 400) {
        // get error message
        const response = await result.json();
        error = response?.message;
      } else {
        error = `Error status: ${result.status} - ${result.statusText}`;
      }
      globalEventDistributor.dispatch({
        type: 'ERROR_FETCHING_C1_TOKEN',
        errorMsg: error,
      });
      console.error(error);
    }
  } catch (e) {
    const error = `Error fetching C1 Token: ${e}`;
    globalEventDistributor.dispatch({
      type: 'ERROR_FETCHING_C1_TOKEN',
      errorMsg: error,
    });
    console.error(error);
  }
};

export const revokeCloudOneToken = async (idToken = false, account = false) => {
  let tokenServiceURL;
  const isSSOv2 = JSON.parse(localStorage.getItem('C1C_SSO_V2_ENABLED') || 'false');
  const c1TokenID = idToken
    ? idToken
    : getSessionStorageJSONValue('C1C_Shell_State')?.c1Token?.token || false;
  if (!c1TokenID) return;

  if (!isSSOv2) {
    // ssov1
    let regionalUrl;
    switch (DEPLOYMENT_TARGET) {
      case 'prod':
        regionalUrl = `https://accounts.${region}.cloudone.trendmicro.com/api/accounts/`;
        break;
      case 'stage':
        regionalUrl = `https://accounts.${region}.staging-cloudone.trendmicro.com/api/accounts/`;
        break;
      case 'drsim':
        regionalUrl = `https://accounts.${region}.drsim-cloudone.trendmicro.com/api/accounts/`;
        break;
      case 'alpha':
      case 'dev':
        regionalUrl =
          window.location.hostname === 'localhost'
            ? '/api/accounts/'
            : `https://accounts.${region}.dev-cloudone.trendmicro.com/api/accounts/`;
        break;
      default:
        regionalUrl = null;
        return console.error('No regionalUrl found');
    }

    const {
      cloudOne: { principal },
      jti,
    } = await getCallerIdentity(regionalUrl, c1TokenID);
    const c1PrincipalAccountID = principal?.account ?? false;

    if (!c1PrincipalAccountID) {
      throw new Error(`expected cloudOne principal account to be present.`);
    }

    switch (DEPLOYMENT_TARGET) {
      case 'prod':
      case 'stage':
      case 'drsim':
        tokenServiceURL = `${regionalUrl}${c1PrincipalAccountID}/tokens/${jti}`;
        break;
      case 'alpha':
      case 'dev':
        tokenServiceURL =
          window.location.hostname === 'localhost'
            ? '/api/accounts/102040608100/tokens'
            : `${regionalUrl}${c1PrincipalAccountID}/tokens/${jti}`;
        break;
      default:
        tokenServiceURL = null;
        return console.error('No token service url found');
    }
  } else {
    // SSov2
    if (!account) {
      const c1AccountsList =
        getSessionStorageJSONValue('C1C_Shell_State')?.c1Accounts?.c1AccountsList || [];
      account =
        c1AccountsList.find(
          ({ id }) => id === getSessionStorageJSONValue('C1C_Shell_State')?.c1Accounts?.c1AccountID
        ) || '';
    }

    let tokenURL = getTokensEndPoint(account);
    if (!tokenURL) {
      throw new Error(`Unable to retrieve C1 Tokens end-point`);
    }

    const { jti } = await getCallerIdentity(tokenURL, c1TokenID);
    if (!jti) {
      throw new Error(`Missing jti property from c1Token`);
    }

    if (tokenURL.indexOf('?roleID=') !== -1) {
      const noRoleIdTokenURL = tokenURL.split('?roleID=')[0];
      tokenServiceURL = `${noRoleIdTokenURL}/${jti}`;
    } else {
      tokenServiceURL = `${tokenURL}/${jti}`;
    }
  }

  try {
    const config = {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-store',
      headers: {
        Authorization: `Bearer ${c1TokenID}`,
        'api-version': process.env.API_VERSION,
      },
    };

    const response = await fetch(tokenServiceURL, config);

    if (response.ok) {
      globalEventDistributor.dispatch({
        type: 'C1_TOKEN_REVOKED_STATUS',
        tokenIsRevoked: true,
      });
      return { tokenIsRevoked: true };
    } else {
      const error = `Failed to revoke token: ${response.status} - ${response.statusText}`;
      console.error(error);
      globalEventDistributor.dispatch({
        type: 'C1_TOKEN_REVOKED_STATUS',
        tokenIsRevoked: false,
      });
      return { tokenIsRevoked: false };
    }
  } catch (e) {
    const error = `Error revoking token: ${e}`;
    console.error(error);
  }
};

export const refreshTokens = async (idToken, token, account) => {
  if (!idToken || !account) {
    console.log('Missing token and/or account');
    clearInterval(refreshTokensTimer);
    return undefined;
  }

  clearInterval(refreshTokensTimer);

  try {
    const config = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-store',
      body: JSON.stringify({
        token: token,
      }),
      headers: {
        'api-version': process.env.API_VERSION,
        Authorization: `Bearer ${idToken}`,
      },
    };

    const tokenURL = getTokensEndPoint(account);
    const requestURL = `${tokenURL.split('/api/accounts/')[0]}/api/refresh-token`;

    if (!tokenURL) {
      console.error('No token end-point found');
      return;
    }

    const result = await fetch(requestURL, config);

    if (result.ok) {
      // retrieve new c1 token
      const { token } = await result.json();
      // extract exp value from JWT
      const {
        exp,
        cloudOne: { principal, role },
      } = await getCallerIdentity(tokenURL, token);

      // update shell state with new token
      globalEventDistributor.dispatch({
        type: 'STORE_C1_TOKEN',
        c1Token: {
          token: token,
          expires: exp,
          userUrn: principal?.urn ?? '',
          roleName: role.name,
          role: principal?.role ?? '',
          serviceRoleUrnList: role?.serviceRoleURNs ?? [],
        },
      });

      const expiresInSeconds = exp - 60;

      refreshTokensTimer = setInterval(() => {
        console.debug('token refreshing timer started...');
        const idToken = sessionStorage.getItem('idToken');
        if (!idToken) {
          console.debug('token refreshing timer cleared...');
          return clearInterval(refreshTokensTimer);
        }

        const currentTimeInSeconds = Math.floor(Date.now() / 1000);
        if (currentTimeInSeconds >= expiresInSeconds) {
          clearInterval(refreshTokensTimer);
          console.debug('Getting latest token');
          return refreshTokens(idToken, token, account);
        }
      }, 30 * 1000); // 30 seconds

      return { token, expires: exp };
    } else {
      const { message } = await result.json();
      if (result.status === 401 && message.toLowerCase().startsWith('invalid token')) {
        return fetchCloudOneToken(idToken, account);
      } else if (
        result.status === 403 &&
        message.toLowerCase().startsWith('user is not authorized')
      ) {
        console.error(`${result.status}: ${message}`);
        globalEventDistributor.dispatch({ type: 'LOGGING_OUT' });
      }
      return { token: '', expires: '' };
    }
  } catch (e) {
    const error = `Error Refreshing C1 Token: ${e}`;

    globalEventDistributor.dispatch({
      type: 'ERROR_FETCHING_C1_TOKEN',
      errorMsg: error,
    });

    return { token: '', expires: '' };
  }
};

const getTokensEndPoint = (account) => {
  const lastAccessedAccountID = window.localStorage.getItem('lastAccessedAccountID' || '');

  const { c1AccountsList = [] } = getSessionStorageJSONValue('C1C_Shell_State')?.c1Accounts || {};
  const { authType = '' } =
    getSessionStorageJSONValue('C1C_Shell_State')?.c1Common.authDetails || {};

  const currentAccount = account
    ? account
    : c1AccountsList.find((account) => account.id === lastAccessedAccountID) || '';

  if (!currentAccount) {
    console.error('Unable to retrieve current account');
  }

  // Used to request SAML based C1 Token
  const { roleId = '' } = getAccountRoleID(currentAccount);

  let tokenURL = getAccountLinks(currentAccount, 'token');
  // SAML Request
  if (roleId !== '' && authType === 'saml') {
    tokenURL = `${tokenURL}?roleID=${roleId}`;
  }

  if (!tokenURL) {
    console.error('Unable to retrieve token end-point');
  }

  return tokenURL;
};

const getAccountRoleID = (account) => {
  const { storedRoleID = '', updateRoleID } = handleAccountRoleID;
  const lastAccessedAccountID = window.localStorage.getItem('lastAccessedAccountID' || '');

  const { c1AccountsList = [], c1RoleID = '' } =
    getSessionStorageJSONValue('C1C_Shell_State')?.c1Accounts || {};

  if (lastAccessedAccountID && c1RoleID) {
    updateRoleID(c1RoleID);
  }

  const currentAccount = account
    ? account
    : c1AccountsList.find((account) => account.id === lastAccessedAccountID) || {};

  // For SAML Users, the "c1RoleID" value will only become available when an account selection event occurs, to ensure users with
  // a last accessed account id, can continue to retrieve new c1Tokens using the roleID query parameter
  const currentAccountRoleID = c1RoleID
    ? c1RoleID
    : storedRoleID
    ? storedRoleID
    : currentAccount?.roleID;

  if (!currentAccount) {
    console.error('Unable to retrieve current account');
  } else if (!currentAccountRoleID) {
    console.error('No Role ID Found');
  }

  return { roleId: currentAccountRoleID };
};

const getCallerIdentity = async (tokenURL, token) => {
  if (!tokenURL || !token) {
    console.error('Missing token url and/or token');
    return undefined;
  }

  const requestURL = `${tokenURL.split('/api/accounts/')[0]}/api/caller-identity`;

  try {
    const config = {
      method: 'GET',
      headers: {
        'api-version': process.env.API_VERSION,
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetch(requestURL, config);

    if (!response.ok) {
      throw new Error(response?.status);
    }

    const tokenClaim = await response.json();
    return { ...tokenClaim };
  } catch (e) {
    console.error('Error getting caller identity claim ', e);
  }
};
