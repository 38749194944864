import { getAccountsEndpoint } from './fetch-accounts';
import { isGovCloudRegion } from '../util';
import { globalEventDistributor } from '../web-app-shell';

const CONNECTOR_STATE_MAPPING = {
  'esc-connecting': 'migrating',
  'esc-connected': 'migrated',
  'esc-failed': 'failed',
};
// get the migration status from the connector API
export const fetchV1ConnectorState = async (services, c1Token) => {
  const v1Endpoint = services.reduce((endPointUrl, { id, links }) => {
    if (id === 'visionone-connect' && links && !endPointUrl) {
      const findByRelType = links.find(({ rel }) => rel === 'endpoint');
      if (findByRelType?.href) {
        endPointUrl = findByRelType.href;
      }
    }
    return endPointUrl;
  }, undefined);
  if (!v1Endpoint) {
    return console.warn('fetchV1ConnectorState(): visionone-connect Endpoint not found`');
  }

  try {
    const options = {
      headers: {
        'api-version': 'v1',
        Authorization: `Bearer ${c1Token}`,
      },
    };
    const request = await fetch(`${v1Endpoint}api/connectors`, options);

    const { services: connectorServicesStatus = [] } = await request.json();
    // store the connector status
    globalEventDistributor.dispatch({
      type: 'STORE_ACCOUNT_MIGRATED_SERVICES_STATE',
      migratedServices: connectorServicesStatus.reduce((result, { name, status }) => {
        return {
          ...result,
          [name]: {
            state: CONNECTOR_STATE_MAPPING[status],
          },
        };
      }, {}),
    });

    if (!request.ok) {
      throw new Error(await request.text());
    }
  } catch (e) {
    console.error('fetchCurrentAccountUpdateState(): Error while fetching the account data');
  }
};
// Gets the current account C1WS to V1ES migration state
export const fetchAccountUpdateState = async (account, c1Token) => {
  const { id: accountId } = account;

  try {
    const accountsEndPoint = getAccountsEndpoint();
    const options = {
      headers: {
        'api-version': 'v1',
        Authorization: `Bearer ${c1Token}`,
      },
    };

    const request = await fetch(`${accountsEndPoint}/${accountId}`, options);
    const { migratedServices = {} } = await request.json();
    // store the next account update state
    globalEventDistributor.dispatch({
      type: 'STORE_ACCOUNT_MIGRATED_SERVICES_STATE',
      migratedServices,
    });

    if (!request.ok) {
      throw new Error(await request.text());
    }
  } catch (e) {
    console.error('fetchCurrentAccountUpdateState(): Error while fetching the account data');
  }
};
