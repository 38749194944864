import { getAccountLinks, getSessionStorageJSONValue } from './helpers';
import { globalEventDistributor } from '../web-app-shell';
import { fetchCloudOneTokenWithID } from './fetch-c1token';
import { reviewServicesLicense } from './../util';

const API_VERSION = 'v1';

/**
 * Fetch Services for SSO v1.5 - where accounts service is not available
 * @param {c1AccountId} c1AccountId
 * @returns
 */
export const fetchAllServices = async (c1AccountId) => {
  try {
    let cloudOneToken = '';
    let servicesEndPoint;
    const region =
      DEPLOYMENT_TARGET === 'alpha' || DEPLOYMENT_TARGET === 'dev' ? `trend-us-1` : 'us-1';
    switch (DEPLOYMENT_TARGET) {
      case 'prod':
        servicesEndPoint = `https://services.${region}.cloudone.trendmicro.com/api/services`;
        break;
      case 'stage':
        servicesEndPoint = `https://services.${region}.staging-cloudone.trendmicro.com/api/services`;
        break;
      case 'drsim':
        servicesEndPoint = `https://services.us-1.drsim-cloudone.trendmicro.com/api/accounts`;
        break;
      case 'govcloud-prod':
        servicesEndPoint = `https://services.gov-us-1.cloudonegov.trendmicro.com`;
        break;
      case 'govcloud-staging':
        servicesEndPoint = 'https://services.gov-us-1.staging-cloudonegov.trendmicro.com';
        break;
      case 'alpha':
      case 'dev':
        servicesEndPoint =
          window.location.hostname === 'localhost'
            ? '/api/services'
            : `https://services.${region}.dev-cloudone.trendmicro.com/api/services`;
        break;
      default:
        servicesEndPoint = null;
        return console.error('No services end-point found');
    }

    if (typeof window !== 'undefined') {
      cloudOneToken = getSessionStorageJSONValue('C1C_Shell_State').c1Token.token;
    }

    if (cloudOneToken === '' || c1AccountId === '') {
      const { token } = await fetchCloudOneTokenWithID(c1AccountId, false);
      cloudOneToken = token;
    }

    if (cloudOneToken) {
      const result = await fetch(servicesEndPoint, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store',
        headers: {
          'api-version': API_VERSION,
          Authorization: `Bearer ${cloudOneToken}`,
        },
      });

      if (result.ok) {
        const servicesList = reviewServicesLicense(await result.json());
        if (servicesList) {
          globalEventDistributor.dispatch({
            type: 'STORE_SERVICES',
            c1ServicesList: servicesList.services,
          });
        }
        return servicesList;
      }
    } else {
      const error = `Error status: ${result.status} - ${result.statusText}`;
      globalEventDistributor.dispatch({
        type: 'ERROR_FETCHING_SERVICES',
        errorMsg: error,
      });
      console.error(error);
    }
  } catch (e) {
    const error = `Error fetching services: ${e}`;
    globalEventDistributor.dispatch({
      type: 'ERROR_FETCHING_SERVICES',
      errorMsg: error,
    });
    console.error(error);
  }
};

export const fetchServicesByAccount = async (cloudOneToken, currentAccount) => {
  if (!cloudOneToken && !currentAccount) {
    console.error('Unable to retrieve services: missing token or account');
    return;
  }

  // Get Services End-Point from Accounts API payload
  const servicesEndPoint = getAccountLinks(currentAccount, 'services');

  if (!servicesEndPoint) {
    console.error('Unable to retrieve services end-point');
    return;
  }

  try {
    const config = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-store',
      headers: {
        'api-version': API_VERSION,
        Authorization: `Bearer ${cloudOneToken}`,
      },
    };
    const result = await fetch(servicesEndPoint, config);
    if (result.ok) {
      const { services } = await result.json();
      return globalEventDistributor.dispatch({
        type: 'STORE_SERVICES',
        c1ServicesList: reviewServicesLicense(services),
      });
    }
  } catch (e) {
    const error = `Error fetching services: ${e}`;
    globalEventDistributor.dispatch({
      type: 'ERROR_FETCHING_SERVICES',
      errorMsg: error,
    });
    console.error(error);
  }
};
