export const pendoEnabled = true;
export const pendoGUID = '65e91197-11f7-47ac-567f-66281e1f9426';

// guide validation is enabled in 'prod' only, OR if its forced enabled by local storage. This
// allows us to test guide validation prior to prod.
export const pendoGuideValidationEnabled =
  DEPLOYMENT_TARGET == 'prod' || localStorage.getItem('C1C_PENDOGUIDEVALIDATION_FORCE_ENABLED');
export const pendoHashedGuideStrings = [
  // PLACEHOLDER - leave this 000 entry here, use this as a template
  // Name: <name of guide>
  // Contact: <team> - <name>
  // For Pendo Custom Code, this hash should match the <hash>.js file name under src/pendo-custom-code
  '0000000000000000000000000000000000000000000000000000000000000000',
  // Name: FSS - GCP Announcement
  // Contact: FSS - Jim Lin & Yuning
  '6d4c95e61d9c29a1dafb0044ecfed5d6925fe86e41e2b3fd579453e15057e236',
  // Name: NS - Feature Announcement - Filter
  // Contact: NS - Yvonne Huang
  '148f1ea514c537e71e26851e9cf329df60fd59da46f851f9e79a020318ab717e',
  // Name: NS - Revivsion to Value proposition statement 4 - Get Started
  // Contact: NS - Takahiro Ishii
  '1f6046f33984ae8e1091ae2342c026668b130a389815d6e99f1c4a04adc4b214',
  // Name: NS - C1NS: Security Alert Log4j vulnerability - Pendo guide
  // Contact: NS - Takahiro Ishii
  '74df091ddd5fc364f7ca978b2c1418f74a773ee5efeafb11f8aa870cd11e293e',
  // Name: Intro guide to Conformity and FSS for trial custoemrs on main landing page
  // Contact: C1 - Vanessa Scott
  'a31e9ff5ea0735779c7f7f9edbf46478be0aecd7531bd84af811ec2fc1674081',
  // Name: CS_ClusterGuide
  // Contact: CS - Derek Hawker
  '3021671b1f723b52a1ce5a6ea08492b1667f2c96f7a834c6681228fdb879c065',
  '0925b19b2486c7abb880eaeb976b8eea9bddcdaa3b5c0cb8351c5f878a30e06e',
  // Name: AS_General_Onboarding
  // Contact: AS - Mathieu Labbé
  '9b229c6e389fb735fc4f57a8132c0ce2603a6d9a3bdc758713d23b7d110b80f1',
  // Name: AS_Posture_Feature
  // Contact: AS - Mathieu Labbé
  '81ddc43ac27528acc45c29e0e413daefae64c868f5a83f40943e25fb045d2cf7',
  // Name: AS_Posture_Setup
  // Contact: AS - Mathieu Labbé
  'b095f2573f28cf76735d462d1579e431f4735b7dbda207ee0d773b9a86f9e6a1',
  // Name: AS_Posture_Reports
  // Contact: AS - Mathieu Labbé
  '9b09bce4c8e8b0e4fc4ff00abb9d4cfb44a25ba9b727c1b04ad161cf85224f0b',
  // Name: SupportChannelSurvey
  // Contact: C1 - Vanessa Scott
  'acd9ac30fc5dff29f2ba0bf301e4d2d2d7cd9af86d52bb37cc696e33cbd8d72d',
  // Name: OSS_Maintenance_Note
  // Contact: C1 - Raphael Bottino
  '74eaf0c97ee236bb0a842027c33ffec50470e13fb884b4b391d2a29598a1c0c8',
  // Name: WS - New Feature Intro - Advanced TLS Traffic Inspection
  // Contact: WS - Hyde Dong
  'd879cbfb7c4da35957b1c4e9889bbc20ff356918cfae1ec6ee5d9c3c2283b920',
  // Name: C1_Outbound_Integrations_Intro
  // Contact: C1 - Johan Cornelissen, Ronny Ilaya
  '1ef7590bec0987dd6cdebd4f6821c0689f06fd67ec317c2e252100a0f06cd099',
  // Name: C1_CloudOneCentral_Tour_Part1
  // Contact: C1 - Nicolas Boutmy
  '1da6e04fd8474be4055d5930324cd29ed815226530b32d972ec7e5856685bc3c',
  // Name: C1_CloudOneCentral_Tour_Part2
  // Contact: C1 - Nicolas Boutmy
  '8eae51b518027215bcf01298bf42e229592cb7a19066bc9483c9619789b373e3',
  // Name: OSS_Maintenance_Note_Feb15
  // Contact: C1 - Raphael Bottino
  'd18d246e47c56e874199ca60c24ada03083a389b5397c4597b0027c8021053d0',
];
