import * as LDClient from 'launchdarkly-js-client-sdk';

// NOTE: the client-side ID determines the environment-specific feature flags (development, production, etc).
// It is not a secret and can be exposed safely in the client-side code.
// https://docs.launchdarkly.com/sdk/client-side/javascript
const getSDKClientSideKey = () => {
  switch (DEPLOYMENT_TARGET) {
    case 'dev':
    case 'alpha':
      return '6202caf6199b2d155340bb82';
    case 'stage':
      return '5c891cf2e6e9691dfc56626e';
    case 'prod':
      return '5c891c9f7dd6fb1dfe95ba9a';
    case 'govcloud-staging':
      return '63f90d48d4bba55a983c2707';
    case 'govcloud-prod':
      return '63f90d8dd4bba55a983c2772';
    default:
      return '';
  }
};

// Configuration based on https://docs.launchdarkly.com/sdk/features/service-endpoint-configuration#javascript
const LDServerInstances = ['govcloud-staging', 'govcloud-prod'].includes(DEPLOYMENT_TARGET)
  ? {
      streamUrl: 'https://clientstream.launchdarkly.us',
      baseUrl: 'https://clientsdk.launchdarkly.us',
      eventsUrl: 'https://events.launchdarkly.us',
    }
  : {};

const ldContext = {
  anonymous: true,
  key: `c1-maintenance-mode-${DEPLOYMENT_TARGET}`,
};

export const ldClient = LDClient.initialize(getSDKClientSideKey(), ldContext, {
  sendEvents: false, // disable event tracking
  bootstrap: 'localStorage',
  ...LDServerInstances,
});

const filterFlags = (flagsList, filterList = []) => {
  if (!filterList.length) return flagsList;

  const result = {};
  for (const flagKey of filterList) {
    if (flagsList?.hasOwnProperty(flagKey)) {
      result[flagKey] = flagsList[flagKey];
    }
  }
  return result;
};

export const setupFeatureFlagsListeners = (flagsToCheck, callback) => {
  if (!ldClient || !callback) {
    console.error('[web-app-shell.setupFeatureFlagsListeners()] Missing required parameters');
    return;
  }

  ldClient.on('initialized', () => {
    const flags = ldClient.allFlags();
    callback(filterFlags(flags, flagsToCheck));
  });
  ldClient.on('change', (/* event */) => {
    // NOTE: Can be improved later on with proper handling of the change event.
    const flags = ldClient.allFlags();
    callback(filterFlags(flags, flagsToCheck));
  });
  ldClient.on('error', (err) => {
    console.error(
      '[web-app-shell.setupFeatureFlagsListeners()] Error connecting to LaunchDarkly:',
      err
    );
  });

  // unsubscribe from events
  return () => {
    ldClient.off('initialized', callback);
    ldClient.off('change', callback);
    ldClient.off('error', callback);
  };
};
