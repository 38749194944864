/**
 * Fetch Invitations
 */
import { globalEventDistributor } from '../web-app-shell';
const fetchReceivedInvitations = async () => {
  const idToken = sessionStorage.getItem('idToken') || false;

  if (!idToken) {
    console.error('Missing ID Token');
    return;
  }

  let accountsEndPoint;
  switch (DEPLOYMENT_TARGET) {
    case 'prod':
      accountsEndPoint = `https://accounts.cloudone.trendmicro.com/api/invitations/received`;
      break;
    case 'stage':
      accountsEndPoint = `https://accounts.staging-cloudone.trendmicro.com/api/invitations/received`;
      break;
    case 'drsim':
      accountsEndPoint = `https://accounts.drsim-cloudone.trendmicro.com/api/invitations/received`;
      break;
    case 'govcloud-prod':
      accountsEndPoint = 'https://accounts.cloudonegov.trendmicro.com/api/invitations/received';
      break;
    case 'govcloud-staging':
      accountsEndPoint =
        'https://accounts.staging-cloudonegov.trendmicro.com/api/invitations/received';
      break;
    case 'alpha':
    case 'dev':
      accountsEndPoint =
        window.location.hostname === 'localhost'
          ? '/api/invitations/received'
          : `https://accounts.dev-cloudone.trendmicro.com/api/invitations/received`;
      break;
    default:
      accountsEndPoint = null;
      return console.error('No accounts end-point found.');
  }

  try {
    const invitationsList = { invitations: [] };

    const getFullInvitationsList = async (cursor = '') => {
      const options = {
        headers: {
          'api-version': 'v1',
          Authorization: `Bearer ${idToken}`,
        },
      };

      const params = {
        limit: 25,
        cursor: cursor,
        rel: 'next',
      };

      const request = await fetch(
        `${accountsEndPoint}?${new URLSearchParams(params).toString()}`,
        options
      );

      const { invitations = [], next = false, message = '' } = await request.json();

      if (!request.ok) {
        throw new Error(message);
      }

      if (invitations) {
        invitationsList.invitations.push(...invitations);
      }

      if (next) {
        return getFullInvitationsList(next);
      }

      return invitationsList;
    };

    const entireReceivedInvitationsList = await getFullInvitationsList();
    if (entireReceivedInvitationsList) {
      const { invitations = [] } = entireReceivedInvitationsList;

      if (invitations && invitations.length > 0) {
        globalEventDistributor.dispatch({
          type: 'STORE_RECEIVED_INVITATIONS',
          invitationsReceived: invitations,
        });
      }
      return invitations;
    }
  } catch (error) {
    if (error.response) {
      return { invitations: null, status: error.response.status };
    } else {
      // Something happened in setting up the request that triggered an Error
      console.debug('Error Fetching Account Details: ', error);
    }
  }
};

export default fetchReceivedInvitations;
