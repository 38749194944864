import { globalEventDistributor } from '../web-app-shell';
import { filterAccounts, getSessionStorageJSONValue } from './helpers';
import fetchReceivedInvitations from './fetch-invitation-services';

export const getAccountsEndpoint = () => {
  let accountsEndPoint;
  switch (DEPLOYMENT_TARGET) {
    case 'prod':
      accountsEndPoint = `https://accounts.cloudone.trendmicro.com/api/accounts`;
      break;
    case 'stage':
      accountsEndPoint = `https://accounts.staging-cloudone.trendmicro.com/api/accounts`;
      break;
    case 'drsim':
      accountsEndPoint = `https://accounts.drsim-cloudone.trendmicro.com/api/accounts`;
      break;
    case 'govcloud-staging':
      accountsEndPoint = 'https://accounts.staging-cloudonegov.trendmicro.com/api/accounts';
      break;
    case 'govcloud-prod':
      accountsEndPoint = 'https://accounts.cloudonegov.trendmicro.com/api/accounts';
      break;
    case 'alpha':
    case 'dev':
      accountsEndPoint =
        window.location.hostname === 'localhost'
          ? '/api/accounts'
          : `https://accounts.dev-cloudone.trendmicro.com/api/accounts`;
      break;
    default:
      accountsEndPoint = null;
      console.error('No accounts end-point found.');
  }
  return accountsEndPoint;
};
const fetchAccounts = async (showInitialInvitationsPage = true, urlHasInvitationID = false) => {
  const idToken = sessionStorage.getItem('idToken') || false;

  if (!idToken) {
    console.error('Unable to retrieve accounts: missing token');
    return;
  }

  const accountsEndPoint = getAccountsEndpoint();

  try {
    const accountsList = { accounts: [] };

    const getFullAccountsList = async (cursor = '') => {
      const options = {
        headers: {
          'api-version': 'v1',
          Authorization: `Bearer ${idToken}`,
        },
      };

      const params = {
        limit: 25,
        cursor: cursor,
        rel: 'next',
      };

      const request = await fetch(
        `${accountsEndPoint}?${new URLSearchParams(params).toString()}`,
        options
      );
      const { accounts = [], next = false, message = '' } = await request.json();

      if (!request.ok) {
        throw new Error(message);
      }

      if (accounts) {
        accountsList.accounts.push(...accounts);
      }

      if (next) {
        return getFullAccountsList(next);
      }

      return accountsList;
    };

    /**
     * Check Invitations
     * @returns {Promise<{hasInvitations: boolean}>}
     */
    const checkInvitations = async () => {
      const invitations = await fetchReceivedInvitations();
      // check if user has pending invitations then redirect to My Invitations Page
      if (invitations && invitations.length > 0) {
        const hasPendingInvitation = invitations.some(
          (invitation) => invitation.state === 'invited'
        );
        if (hasPendingInvitation && showInitialInvitationsPage) {
          globalEventDistributor.dispatch({
            type: 'SHOW_INITIAL_INVITATION_PAGE',
            showInitialInvitationsPage: false,
          });
          return { hasInvitations: true };
        }
      }
      return { hasInvitations: false };
    };

    const entireAccountsList = await getFullAccountsList();
    if (entireAccountsList) {
      const { accounts: accountsList = [] } = entireAccountsList;
      // check the accounts list for accounts that have state of active.
      const activeAccounts =
        accountsList && accountsList.filter((account) => account.state === 'active');

      // If there are active accounts => proceed next
      if (activeAccounts.length > 0) {
        // Filter Accounts
        filterAccounts(activeAccounts);

        // Dispatch Accounts to Store
        globalEventDistributor.dispatch({
          type: 'STORE_ACCOUNTS',
          storeAccounts: activeAccounts,
        });
      } else if (urlHasInvitationID) {
        // If url has invitation ID redirect to my invitation view for users with no accounts
        globalEventDistributor.dispatch({
          type: 'SHOW_INITIAL_INVITATION_PAGE',
          showInitialInvitationsPage: false,
        });
        globalEventDistributor.dispatch({
          type: 'URL_HAS_INVITATION_ID',
          urlHasInvitationID: false,
        });
        return globalEventDistributor.dispatch({
          type: 'NAVIGATE_APP',
          appName: 'my-user/invitations',
        });
      } else {
        // SAML User will be prevented from seeing invitations & cannot create an account
        const { authType = '' } =
          getSessionStorageJSONValue('C1C_Shell_State')?.c1Common.authDetails || {};
        if (authType === 'saml') {
          return globalEventDistributor.dispatch({
            type: 'NAVIGATE_APP',
            appName: 'account-selection',
          });
        }

        // If user has no active accounts available and has invitations redirect to my invitation view else to create accounts view
        const { hasInvitations } = await checkInvitations();
        if (hasInvitations) {
          return globalEventDistributor.dispatch({
            type: 'NAVIGATE_APP',
            appName: 'my-user/invitations',
          });
        }
        return globalEventDistributor.dispatch({
          type: 'NAVIGATE_APP',
          appName: 'create-account',
        });
      }
    } else {
      const error = `Error status: ${accounts.status} - ${accounts.statusText}`;
      globalEventDistributor.dispatch({
        type: 'ERROR_FETCHING_ACCOUNTS',
        errorMsg: error,
      });
      console.error(error);
    }
  } catch (error) {
    const errorMsg = error?.message ? error?.message : `Error fetching accounts: ${error}`;
    globalEventDistributor.dispatch({
      type: 'ERROR_FETCHING_ACCOUNTS',
      errorMsg: errorMsg,
    });
    console.debug(errorMsg);
  }
};

export default fetchAccounts;
