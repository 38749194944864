import { globalEventDistributor } from '../web-app-shell';
import { fetchServicesByAccount, revokeCloudOneToken, fetchCloudOneToken } from './';

/**
 * Retrieve URL Href
 * @param account
 * @param relType
 * @returns {undefined|*}
 */
export const getAccountLinks = (account, relType) => {
  if (!account) {
    return undefined;
  }

  return [account].reduce((serviceUrl, { links }) => {
    if (links && !serviceUrl) {
      const findByRelType = links.find(({ rel }) => rel === relType);
      if (findByRelType) serviceUrl = findByRelType.href;
    }
    return serviceUrl;
  }, null);
};

/**
 * Filter and Store the last accessed account
 * @param accounts
 */
export const filterAccounts = (accounts) => {
  const idToken = sessionStorage.getItem('idToken');
  const lastAccessedAccountID = window.localStorage.getItem('lastAccessedAccountID' || '');
  const hasMarketplaceRedirect = window.localStorage.getItem('C1C_MarketplaceRequest') || false;

  if (accounts && accounts.length === 1 && !hasMarketplaceRedirect) {
    const { id: accountID = '', roleID = '' } = accounts[0] || {};

    if (roleID) {
      const { updateRoleID } = handleAccountRoleID;
      updateRoleID(roleID);
    }

    localStorage.setItem('lastAccessedAccountID', accountID);
    globalEventDistributor.dispatch({
      type: 'ADD_LAST_SELECTED_ACCOUNT_ID',
      c1AccountID: accountID,
      c1RoleID: roleID,
    });

    return loadServices(accounts[0], idToken);
  }

  if (accounts && !accounts.some((account) => account.id === lastAccessedAccountID)) {
    return globalEventDistributor.dispatch({
      type: 'NAVIGATE_APP',
      appName: 'account-selection',
    });
  } else {
    const selectedAccount = accounts.find((account) => account.id === lastAccessedAccountID);
    return loadServices(selectedAccount, idToken);
  }
};
/**
 * Load Services: Fetch c1 token and use it to make fetch services request
 * @param account
 * @param idToken
 * @returns {Promise<void>}
 */

let currentSessionDetails = {};
export const updateCurrentSessionDetails = (value = {}) => (currentSessionDetails = value);

export const loadServices = async (account, idToken) => {
  try {
    // get a new token first
    const { token = '' } = await fetchCloudOneToken(idToken, account);
    if (currentSessionDetails?.idToken) {
      const { idToken, currentAccount } = currentSessionDetails;
      // clear old stored value
      currentSessionDetails = {};
      // Revoke current/last token
      await revokeCloudOneToken(idToken, currentAccount);
    }

    if (token && token.length > 1) {
      // set current token to the new token
      currentSessionDetails = {
        idToken: token,
        currentAccount: account,
      };

      // storage the last access account id
      localStorage.setItem('lastAccessedAccountID', account.id);

      try {
        await fetchServicesByAccount(token, account);
      } catch (e) {
        console.error('Error fetching services: ', e);
      }
    } else {
      console.error('Unable to retrieve C1 token');
    }
  } catch (e) {
    console.error('Error fetching c1 token: ', e);
  }
};

export const getStorageValue = (item, storage) => {
  if (item && storage) {
    storage = storage === 'localStorage' ? localStorage : sessionStorage;
    if (storage.getItem(item) !== null) {
      return JSON.parse(storage.getItem(item)) || {};
    } else {
      return false;
    }
  } else {
    console.error('Missing parameters');
    return false;
  }
};

/**
 * Public Routes in Auth App
 * @type {string[]}
 */
export const publicRoutes = [
  'register',
  'login',
  'forgot-password',
  'confirmation',
  'mfa-login',
  'mfa-reset',
  'reset-password',
  'legacy-rescue',
  'resend-confirmation',
  'trial',
  'endpoint-trial',
  'update-account-confirmation',
  'signin',
  'legacy-login',
];

export const isSSOv2UserLoggedIn = () =>
  getSessionStorageJSONValue('C1C_Shell_State')?.c1Common?.loggedIn ?? false;

export const getSessionStorageJSONValue = (item) => getStorageValue(item, 'sessionStorage');
export const getLocalStorageJSONValue = (item) => getStorageValue(item, 'localStorage');

/**
 * Update and Fetch Current Account Role ID
 * @type {{storedRoleID: (string|string), updateRoleID: (function(*): void)}}
 */
export const handleAccountRoleID = (() => {
  const getStoredRoleID = () => window.localStorage.getItem('lastAccessedRoleID') || '';

  const updateRoleID = (c1RoleID = '') => {
    if (c1RoleID) {
      window.localStorage.setItem('lastAccessedRoleID', c1RoleID);
    }
  };

  return {
    storedRoleID: getStoredRoleID(),
    updateRoleID: (c1RoleID) => updateRoleID(c1RoleID),
  };
})();

export const getV1PortalURL = () => {
  switch (process.env.DEPLOYMENT_TARGET) {
    case 'alpha':
    case 'dev':
      return 'https://signin-int.visionone.trendmicro.com/';
    case 'stage':
      return 'https://signin-xdr.visionone.trendmicro.com/';
    case 'prod':
    default:
      return 'https://signin.v1.trendmicro.com/';
  }
};

export const navigateToV1ES = () => {
  window.open(getV1PortalURL(), '_self', 'noopener,noreferrer');
};
