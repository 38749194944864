import { applyMiddleware, combineReducers, createStore } from 'redux';
import {
  checkCurrentCognitoSession,
  checkCurrentSAMLSession,
  globalEventDistributor,
  handleLogout,
  handleRevokeC1Token,
} from './web-app-shell';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { Auth } from '@aws-amplify/auth';
import { fetchCloudOneToken, fetchServicesByAccount } from './services';
import { handleAccountRoleID, updateCurrentSessionDetails } from './services/helpers';
import { isGovCloudRegion } from './util';
import { fetchAccountUpdateState } from './services/fetch-v1-update-state';
import { hasProdLogsEnabled } from './util';

const c1AccountsInitState = {
  requestAccounts: false,
  hasError: false,
  hasInitialAccountId: false,
  c1AccountID: '',
  c1RoleID: '',
  c1AccountsList: [],
  lastAccessedAccountID: '',
  fetchRecInvitations: false,
  invitationsReceived: [],
  showInitialInvitationsPage: true,
  urlHasInvitationID: false,
  migratedServices: {},
  // TODO: remove this state when v1->v2 migration is done
  // isCloudOneBacked: true to avoid showing banner or causing redirect initially
  cloudOneBackedStatus: {
    isCloudOneBacked: true,
    showInitialMigrationPage: true,
  },
  redirectToV1UpdateAccount: true, // set true to redirect full-access user to update-account page on sign in
  c1CloudProviders: {},
  c1Central: {
    projectID: '',
  },
};

const c1ServicesInitState = {
  hasError: false,
  fetchServices: false,
  c1ServicesList: [],
};

const c1TokenInitState = {
  token: '',
  expires: '',
  hasError: false,
  tokenIsRevoked: false,
  fetchC1Token: false,
  userUrn: '',
  role: '',
  roleName: '',
  serviceRoleUrnList: [],
};

const c1UserInfoInitState = {
  name: '',
  email: '',
  timezone: '',
  hasError: false,
  type: '',
  wsAdminID: null,
  hasMFAResetCode: null,
};

const commonInitState = {
  storeId: 'web-app-shell',
  appSelection: '',
  wsLoggedIn: false, //WS session state during dual sign-in phase
  wsNavigationRestricted: false, //WS session state during rescue login sign-in phase
  loggedIn: false,
  loggingOut: false,
  globalSignOut: false, // Enable global sign-out
  authDetails: { authType: '', expires: '' }, // authentication details
  hasError: false,
  locale: 'en',
  errorMsg: '',
  isLoading: false,
  theme: { colorMode: 'light', overrides: {} },
};

const c1MaintenanceModeState = {
  enabled: false,
  bannerMessage: {},
  servicesRestrictions: {},
};

const c1AccountsListReducer = (state = c1AccountsInitState, action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNTS':
      return {
        ...state,
        requestAccounts: action.requestAccounts,
      };
    case 'STORE_ACCOUNT_MIGRATED_SERVICES_STATE':
      return {
        ...state,
        migratedServices: action.migratedServices,
      };
    case 'ADD_LAST_SELECTED_ACCOUNT_ID':
      return {
        ...state,
        hasInitialAccountId: true,
        lastAccessedAccountID: action.c1AccountID,
        c1AccountID: action.c1AccountID,
        c1RoleID: action.c1RoleID,
      };
    case 'RESET_LAST_SELECTED_ACCOUNT_DETAILS':
      return {
        ...state,
        hasInitialAccountId: false,
        lastAccessedAccountID: '',
        c1AccountID: '',
      };
    case 'ADD_ACCOUNT_ID':
    case 'FETCH_BY_ACCOUNT_ID':
      return {
        ...state,
        hasInitialAccountId: false,
        lastAccessedAccountID: state.c1AccountID,
        c1AccountID: action.c1AccountID,
        c1RoleID: action.c1RoleID,
        c1CloudProviders: {},
        c1Central: {
          projectID: '',
        },
      };
    case 'ADD_ACCOUNTS':
    case 'STORE_ACCOUNTS':
      return {
        ...state,
        hasInitialAccountId: false,
        c1AccountsList: action.storeAccounts,
        hasError: false,
        errorMsg: '',
        requestAccounts: false,
      };
    case 'ADD_LICENSE_TYPE':
      // Store WS License Type only
      return {
        ...state,
        licenseType: action.licenseType,
      };
    case 'ERROR_FETCHING_ACCOUNTS':
      return {
        ...state,
        ...c1AccountsInitState,
        hasError: true,
        requestAccounts: false,
        fetchRecInvitations: false,
        errorMsg: action.errorMsg,
      };
    case 'STORE_RECEIVED_INVITATIONS':
      return {
        ...state,
        hasError: false,
        fetchRecInvitations: false,
        invitationsReceived: action.invitationsReceived,
      };
    // showInitialInvitationsPage flag is used to avoid redirecting users back to invitations page
    // if user clicks on Create New Account button on my invitations page without accepting or rejecting any invitation
    case 'SHOW_INITIAL_INVITATION_PAGE': {
      return {
        ...state,
        showInitialInvitationsPage: action.showInitialInvitationsPage,
      };
    }
    case 'FETCH_RECEIVED_INVITATIONS': {
      return {
        ...state,
        fetchRecInvitations: action.fetchRecInvitations,
      };
    }
    case 'URL_HAS_INVITATION_ID': {
      return {
        ...state,
        urlHasInvitationID: action.urlHasInvitationID,
      };
    }
    case 'ADD_CLOUDONE_BACKED_STATUS':
      return {
        ...state,
        cloudOneBackedStatus: {
          ...state.cloudOneBackedStatus,
          isCloudOneBacked: action.isCloudOneBacked,
        },
      };
    case 'RESET_CLOUDONE_BACKED_STATUS':
      return {
        ...state,
        cloudOneBackedStatus: { ...c1AccountsInitState.cloudOneBackedStatus },
      };
    case 'TOGGLE_SHOW_INITIAL_MIGRATION_PAGE':
      return {
        ...state,
        cloudOneBackedStatus: {
          ...state.cloudOneBackedStatus,
          showInitialMigrationPage: action.showInitialMigrationPage,
        },
      };
    case 'TOGGLE_REDIRECT_TO_V1_UPDATE_ACCOUNT_PAGE':
      return {
        ...state,
        redirectToV1UpdateAccount: action.redirectToV1UpdateAccount,
      };
    case 'UPDATE_CLOUD_PROVIDER_ACCOUNTS':
      return {
        ...state,
        c1CloudProviders: {
          ...state.c1CloudProviders,
          [action.provider]: action.accounts,
        },
      };
    case 'ADD_PROJECT':
      return {
        ...state,
        c1Central: {
          projectID: action.id,
        },
      };
    case 'HYDRATE_STORE':
      return {
        ...state,
        ...action.storeState.c1Accounts,
      };
    case 'RESET_STORE':
      return {
        ...c1AccountsInitState,
      };
    default:
      return state;
  }
};

const c1ServicesListReducer = (state = c1ServicesInitState, action) => {
  switch (action.type) {
    case 'FETCH_SERVICES':
      return {
        ...state,
        fetchServices: action.fetchServices,
      };
    case 'STORE_SERVICES':
      return {
        ...state,
        c1ServicesList: action.c1ServicesList,
        hasError: false,
        errorMsg: '',
        fetchServices: false,
      };
    case 'ERROR_FETCHING_SERVICES':
      return {
        ...state,
        ...c1ServicesInitState,
        hasError: true,
        errorMsg: action.errorMsg,
      };
    case 'HYDRATE_STORE':
      return {
        ...state,
        ...action.storeState.c1Services,
      };
    case 'RESET_STORE':
      return {
        ...c1ServicesInitState,
      };
    default:
      return state;
  }
};

const c1UserInfoReducer = (state = c1UserInfoInitState, action) => {
  switch (action.type) {
    case 'ADD_USER_INFO':
      const { name = '', email = '', timezone = '' } = action.userDetails;
      return {
        ...state,
        name,
        email,
        timezone,
        hasError: false,
        errorMsg: '',
      };
    case 'ADD_WS_USER_INFO':
      const {
        fullName = '',
        emailAddress = '',
        timeZone = '',
        phoneNumber = '',
        mobileNumber = '',
      } = action.userDetails;
      return {
        ...state,
        wsFullName: fullName,
        wsEmailAddress: emailAddress,
        wsTimeZone: timeZone,
        wsPhoneNumber: phoneNumber,
        wsMobileNumber: mobileNumber,
      };
    case 'ADD_WS_USER_EMAIL':
      return {
        ...state,
        wsEmailAddress: action.email,
      };
    case 'ERROR_ADDING_USER_INFO':
      return {
        ...state,
        hasError: true,
        errorMsg: action.errorMsg,
      };
    case 'SET_WS_ADMIN_ID':
      return {
        ...state,
        wsAdminID: action.wsAdminID,
      };
    case 'ADD_USER_TYPE':
      return {
        ...state,
        type: action.userType,
      };
    // Legacy support for older MFA users. If false, indicates that an (old) MFA account does not have a reset code.
    // Any users who currently signs up for MFA will automatically have reset codes.
    case 'TOGGLE_HAS_MFA_RESET_CODE': {
      return {
        ...state,
        hasMFAResetCode: action.hasMFAResetCode,
      };
    }
    case 'HYDRATE_STORE':
      return {
        ...state,
        ...action.storeState.c1UserDetails,
      };
    case 'RESET_STORE':
      return {
        ...c1UserInfoInitState,
      };
    default:
      return state;
  }
};

const c1TokenReducer = (state = c1TokenInitState, action) => {
  switch (action.type) {
    case 'FETCH_C1_TOKEN':
      return {
        ...state,
        fetchC1Token: action.fetchC1Token,
      };
    case 'STORE_C1_TOKEN':
      return {
        ...state,
        ...action.c1Token,
        hasError: false,
        fetchC1Token: false,
        errorMsg: '',
      };
    case 'C1_TOKEN_REVOKED_STATUS':
      return {
        ...state,
        tokenIsRevoked: action.tokenIsRevoked,
      };
    case 'ERROR_FETCHING_C1_TOKEN':
      return {
        ...state,
        ...c1TokenInitState,
        hasError: true,
        errorMsg: action.errorMsg,
      };
    case 'HYDRATE_STORE':
      return {
        ...state,
        ...action.storeState.c1Token,
      };
    case 'RESET_STORE':
      return {
        ...c1TokenInitState,
      };
    default:
      return state;
  }
};

function defaultReducer(state = commonInitState, action) {
  console.log(`Redux store in Root SPA with action:${JSON.stringify(action)}`);
  switch (action.type) {
    case 'NAVIGATE_APP':
      return {
        ...state,
        appSelection: action.appName,
      };
    case 'WS_LOGGED_IN':
      return {
        ...state,
        wsLoggedIn: true,
      };
    case 'WS_RESCUE_LOGGED_IN':
      return {
        ...state,
        wsLoggedIn: true,
        wsNavigationRestricted: true,
      };
    case 'WS_LOGGED_OUT':
      return {
        ...state,
        wsLoggedIn: false,
        wsNavigationRestricted: false,
      };
    case 'LOGGED_IN':
      return {
        ...state,
        loggedIn: true,
        loggingOut: false,
      };
    case 'LOGGING_OUT':
      return {
        ...state,
        loggedIn: false,
        loggingOut: true,
        globalSignOut: false,
      };
    case 'ADD_AUTH_DETAILS': {
      const { authType = '', expires = '' } = action.authDetails;
      return {
        ...state,
        authDetails: {
          authType,
          expires,
        },
      };
    }
    case 'SHOW_LOADING_INDICATOR':
    case 'HIDE_LOADING_INDICATOR': {
      return {
        ...state,
        isLoading: action.loading,
      };
    }
    case 'SET_LOCALE':
      return {
        ...state,
        locale: action.locale,
      };
    case 'SET_THEME':
      return {
        ...state,
        theme: action.theme,
      };
    case 'SET_COLOR_MODE':
      return {
        ...state,
        theme: {
          ...state.theme,
          colorMode: action.colorMode,
        },
      };
    case 'SET_THEME_OVERRIDES':
      return {
        ...state,
        theme: {
          ...state.theme,
          overrides: action.overrides,
        },
      };
    case 'HYDRATE_STORE':
      return {
        ...state,
        ...action.storeState.c1Common,
      };
    case 'RESET_STORE':
      return {
        ...commonInitState,
      };
    default:
      return state;
  }
}

function maintenanceModeReducer(state = c1MaintenanceModeState, action) {
  switch (action.type) {
    case 'MAINTENANCE_MODE_CONFIG_UPDATED':
      return {
        ...state,
        ...action.maintenanceMode,
      };
    default:
      return state;
  }
}

/**
 * Retrieve Persisted State
 * @returns {any|undefined}
 */
export const loadPersistedState = () => {
  try {
    const persistedState = sessionStorage.getItem('C1C_Shell_State');
    if (persistedState === null) {
      return undefined;
    }
    let redux_payload = JSON.parse(persistedState);
    redux_payload.c1Common.appSelection = '';
    return redux_payload;
  } catch (e) {
    console.error('Error retrieving state.');
  }
};

/**
 * Personal Information - PII
 * @type {[{property: string, key: string}]}
 */
const pii = [
  { key: 'c1Token', property: 'userUrn' },
  { key: 'c1Token', property: 'role' },
  { key: 'c1Token', property: 'roleName' },
  { key: 'c1Token', property: 'serviceRoleUrnList' },
  { key: 'c1UserDetails', property: 'wsEmailAddress' },
  { key: 'c1UserDetails', property: 'wsFullName' },
  { key: 'c1UserDetails', property: 'wsMobileNumber' },
  { key: 'c1UserDetails', property: 'wsPhoneNumber' },
];

/**
 * Set state to session storage
 * @param storeState
 */
export const persistState = (storeState) => {
  try {
    const clonedStoreState = JSON.parse(JSON.stringify(storeState));
    const stateToPersist = Object.fromEntries(
      Object.entries(clonedStoreState).map(([key, value]) => {
        const rVal = [key, value];
        pii.forEach((piiEntry) => {
          if (key === piiEntry.key && value[piiEntry.property] !== undefined) {
            delete value[piiEntry.property];
          }
        });
        return rVal;
      })
    );
    sessionStorage.setItem('C1C_Shell_State', JSON.stringify(stateToPersist));
  } catch (e) {
    console.error('Error writing state to storage: ', e);
  }
};

/**
 * Thunk to capture passing of state updates.
 * @param store
 * @returns {function(*): function(*=): *}
 */
const persistThunk = (store) => (next) => (action) => {
  const result = next(action);
  persistState(store.getState());
  return result;
};

/**
 * Custom Logger Middleware
 * @param store
 * @returns {function(*): function(*=): *}
 */
const logger = (store) => (next) => (action) => {
  if (
    (DEPLOYMENT_TARGET !== 'prod' && DEPLOYMENT_TARGET !== 'govcloud-prod') ||
    hasProdLogsEnabled
  ) {
    console.group(action.type, 'web-app-shell');
    console.debug('web-app-shell dispatching', action);

    // Call the next dispatch method in the middleware chain
    const result = next(action);

    console.debug('web-app-shell next state after dispatch', store.getState());
    console.groupEnd();
    return result;
  }
  return next(action);
};

const localeHandler = (store) => (next) => async (action) => {
  if (action.type === 'SET_LOCALE') {
    const [updatedUserlocale = 'en'] = action.locale.split('-');
    let updatedLocale = isGovCloudRegion ? 'en' : updatedUserlocale;
    const currentlocale = store.getState().c1Common.locale;

    if (currentlocale !== updatedLocale) {
      localStorage.setItem(`${process.env.APP_PREFIX}locale`, updatedLocale);
    }

    return next(action);
  }

  return next(action);
};

/**
 * Accounts Related Thunks
 * @param store
 * @returns {function(*): function(*): Promise<*|undefined>}
 */
const accountsHandler = (store) => (next) => async (action) => {
  if (action.type === 'REMOVE_ACCOUNT') {
    //Invalidate the previous c1Token
    await handleRevokeC1Token();
    return next(action);
  }
  return next(action);
};

/**
 * Handle Authentication States
 * @param {*} store
 * @returns
 */
const authenticationHandler = (store) => (next) => async (action) => {
  if (action.type === 'LOGGING_OUT' && store.getState().c1Common.loggedIn) {
    const { authType = '' } = store.getState()?.c1Common?.authDetails || {};
    const isCognitoUser = authType !== 'saml' || false;

    updateCurrentSessionDetails({});

    if (isCognitoUser) {
      // Cognito will sign out globally
      await Auth.signOut({ global: store.getState().c1Common.globalSignOut });
    }

    await handleLogout();
    return next(action);
  }

  if (action.type === 'LOGGED_IN') {
    const { authType = '', expires = '' } = store.getState()?.c1Common?.authDetails || {};
    const isSamlUser = authType === 'saml' || false;
    const lastAccessedAccountID = window.localStorage.getItem('lastAccessedAccountID' || '');
    const { storedRoleID = '' } = handleAccountRoleID;

    if (lastAccessedAccountID) {
      globalEventDistributor.dispatch({
        type: 'ADD_LAST_SELECTED_ACCOUNT_ID',
        c1AccountID: lastAccessedAccountID,
        c1RoleID: storedRoleID,
      });
    }

    if (isSamlUser) {
      checkCurrentSAMLSession(expires);
    } else {
      // start Cognito session tracking
      checkCurrentCognitoSession();
    }

    return next(action);
  }

  return next(action);
};

/**
 * Show Loading Indicator
 * @param {*} store
 * @returns
 */
let isLoading = false;
const showLoadingIndicator = (store) => (next) => (action) => {
  const ACTION_TYPES = [
    'ADD_ACCOUNT_ID',
    'FETCH_ACCOUNTS',
    'FETCH_SERVICES',
    'FETCH_RECEIVED_INVITATIONS',
  ];

  if (ACTION_TYPES.find((type) => type === action.type) && !isLoading) {
    isLoading = true;
    next(action);
    globalEventDistributor.dispatch({ type: 'SHOW_LOADING_INDICATOR', loading: true });
  }
  // set showInitialInvitationsPage to true, to redirect the user to invitations page after we delete the last user account
  if (action.type === 'STORE_ACCOUNTS') {
    isLoading = false;
    next(action);
    if (store.getState().c1Accounts.c1AccountsList.length === 0) {
      globalEventDistributor.dispatch({
        type: 'SHOW_INITIAL_INVITATION_PAGE',
        showInitialInvitationsPage: true,
      });
    }
  }

  if (action.type === 'STORE_SERVICES' || action.type === 'STORE_RECEIVED_INVITATIONS') {
    isLoading = false;
    next(action);
    globalEventDistributor.dispatch({ type: 'HIDE_LOADING_INDICATOR', loading: false });
  }

  return next(action);
};

/**
 * Handle C1Token refresh
 * @param {*} store
 * @returns
 */
let tokenRefreshInProgress;
const refreshC1TokenHandler = (store) => (next) => (action) => {
  const shellState = store.getState();
  if (
    action.type === 'FETCH_C1_TOKEN' &&
    shellState?.c1Accounts &&
    !shellState?.c1Token?.fetchC1Token
  ) {
    const idToken = sessionStorage.getItem('idToken') || false;
    const currentAccount = shellState?.c1Accounts?.c1AccountsList.find(
      ({ id }) => id === shellState?.c1Accounts.c1AccountID
    );
    if (idToken && currentAccount && !tokenRefreshInProgress) {
      // delay c1Token refresh to allow time for DB syncs
      tokenRefreshInProgress = setTimeout(async () => {
        const { token = '' } = await fetchCloudOneToken(idToken, currentAccount);
        if (token) {
          // Get the latest services with the latest c1 token
          await fetchServicesByAccount(token, currentAccount);
          clearTimeout(tokenRefreshInProgress);
          tokenRefreshInProgress = null;
        }
      }, 1000);
    }

    return next(action);
  }

  return next(action);
};

// Middleware used to determine when to fetch the migrated state
let isFetchingServiceMigrationState = false;
const fetchServicesUpdateStateHandler = (store) => (next) => (action) => {
  try {
    // ignore GovCloud targets
    if (
      isGovCloudRegion ||
      !['STORE_C1_TOKEN', 'NAVIGATE_APP', 'STORE_ACCOUNTS', 'STORE_SERVICES'].includes(action.type)
    ) {
      return next(action);
    }

    const shellState = store.getState();
    const accounts = action?.storeAccounts || shellState?.c1Accounts?.c1AccountsList;
    const currentAccount = accounts?.find(({ id }) => id === shellState?.c1Accounts.c1AccountID);
    const services = action?.c1ServicesList || shellState?.c1Services?.c1ServicesList || [];
    const c1Token = action?.c1Token?.token || shellState?.c1Token?.token;

    if (currentAccount?.id && c1Token && services.length) {
      if (!isFetchingServiceMigrationState) {
        isFetchingServiceMigrationState = true;
        fetchAccountUpdateState(currentAccount, c1Token)
          .then(() => {
            isFetchingServiceMigrationState = false;
          })
          .catch(() => {
            isFetchingServiceMigrationState = false;
          });
      }
    } else {
      console.warn(
        'fetchServicesUpdateStateHandler(): Cannot fetch the update state. account, c1Token or services not received yet'
      );
    }
  } finally {
    next(action);
  }
};

const composeEnhancers = composeWithDevTools({
  name: 'web-app-shell',
  trace: true,
  traceLimit: 25,
});

export const storeInstance = createStore(
  combineReducers({
    c1Accounts: c1AccountsListReducer,
    c1Services: c1ServicesListReducer,
    c1Token: c1TokenReducer,
    c1UserDetails: c1UserInfoReducer,
    c1Common: defaultReducer,
    c1MaintenanceMode: maintenanceModeReducer,
  }),
  loadPersistedState(),
  composeEnhancers(
    applyMiddleware(
      thunk,
      persistThunk,
      logger,
      authenticationHandler,
      accountsHandler,
      showLoadingIndicator,
      refreshC1TokenHandler,
      localeHandler,
      fetchServicesUpdateStateHandler
    )
  )
);
